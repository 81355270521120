import * as React from "react"
import Layout from "../components/layout.js"
import { Link } from "gatsby"
import Image from "../components/image"

const LibrarianPage = () => {
  return (
    <Layout title="The Librarian">
        <h2>Field Notes</h2>
        <center><Image src="librarian-1.png" alt="Drawing" /></center>
        <p>The LIBRARIAN is a GOD on the world of her origin.</p>
        <ul>
          <li>The LIBRARIAN can commit any record or story to memory.</li>
          <li>Her eyes resemble a split pomegranate. Each "seed" in her eyes represents a record or story. When reciting a record or story, the relevant "seed" within her eye gives off an eery glow.</li>
          <li>Her enemy is <Link to="../bookburner">the BOOK-BURNER</Link>, though there appears to be far more to their relationship...</li>
          <li>The BOOK-BURNER steals record-seeds from her eyes and burns them. Once burnt, the information in the records is lost forever.</li>
        </ul>
    </Layout>
  )
}

export default LibrarianPage